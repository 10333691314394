html{
  scroll-behavior: smooth;
}

div {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  background-color: transparent;
}

.fondo {
  position: absolute;
  display: block;
  float: left;
  overflow: hidden;
  top: 0;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(117, 117, 117, 0.8);
}

.fondo p {
  position: relative;
  display: table;
  padding: 0;
  margin: 10% 10%;
  width: 80%;
  color: white;
  height: auto;
  text-align: center;
  font-size: 5vmin;
  
}

.fondo p.lower {
  position: relative;
  display: table;
  padding: 0;
  margin: 5% 20%;
  width: 60%;
  color: white;
  height: auto;
  font-size: 4vmin;
  
}

.fondo button {
  display: block;
  padding: 20px 0;
  margin: 2% 35%;
  width: 30%;
  height: auto;
  text-decoration: none;
  background-color: rgb(0, 168, 89);
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  border-radius: 30px;
  border: 0;
  box-shadow: rgb(0, 0, 0, 0.3) 0px 0px 30px 0px;
  transition: box-shadow, background-color, 0.5s ease;
}

.upper {

  text-transform: uppercase;
  font-size: 7vmin;
  border: none;
}

.line-green-5px {
  display: block;
  position: relative;
  width: 15%;
  height: 5px;
  margin: 0 42.5%;
  padding: 0;
  background-color: rgb(0, 153, 102);
  border-radius: 2.5px;
  border: 0px;
}

.line-white-5px {
  width: 15%;
  height: 5px;
  margin: 30px auto;
  padding: 0px;
  background-color: white;
  border-radius: 2.5px;
  border: 0px;
}

.line-yellow-5px {
  width: 15%;
  height: 5px;
  margin: 30px auto;
  padding: 0px;
  background-color: yellow;
  border-radius: 2.5px;
  border: 0px;
}

.fondo div div span.lower {
  font-size: 4vmin;
}

.fondo-img {
  position: relative;
  display: block;
  padding: calc(50vh/2) calc(50vw/2);
  margin: 0;
  height: 50vh;
  width: 50vw;
  background-color: transparent;

}



.boton-saber:hover {
  box-shadow: rgb(0, 0, 0, 0.8) 5px 5px 20px;
  background-color: rgb(0, 168, 89, 0.6);
  transform: scale(1.1);
}

::-webkit-scrollbar {
  display: none;
}

.Content {
  position: relative;
  display: block;
  float: left;
  margin: 0px;
  padding: 80px 0px;
  width: 100%;
  height: 100%;
  background: rgb(0, 168, 89);
}

.Content p.upper {
  position: relative;
  display: block;
  float: left;
  width: 80%;
  margin: 20px 10%;
  font-size: 5vmin;
  text-align: center;
  padding: 0px;
  color: rgba(255, 255, 255);
}

.Content p {
  position: relative;
  display: block;
  float: left;
  width: 80%;
  margin: 5px 10%;
  padding: 0px;
  text-align: justify;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 2.5vmin;
  color: rgba(255, 255, 255, 0.7);
}

.Services{
  
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: auto;
  padding: 50px 0;
}

.Services p.upper {
  position: relative;
  display: block;
  float: left;
  width: 80%;
  margin: 20px 10%;
  font-size: 5vmin;
  text-align: center;
  padding: 0px;
  color: black;
}

.Services > div {
  position: relative;
  display: table;
  float: right;
  margin: 2% 10%;
  width: 80%;
  height: 100%;
  text-align: center;
  /* background-color: aqua; */
}

.Services .iconos {
  display: block;
  width: 30%;
  height: 30%;
  margin: 10% 35%;
  padding: 0;
  /* background-color: red; */
}

.Services span.titulos {
  position: relative;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
  height: 100%;
  width: 100%;
  padding: 10px 0;
  margin: 0;

}

.Services p.desc {
  position: relative;
  display: table-cell;
  padding: 10px 0;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  color: grey;
  font-weight: 100;
  height: 100%;
  width: 100%;
  font-size: 1.2rem;

}

.Portafolio{
  position: relative;
  display: block;
  float: left;
  padding: 50px 0px;
  background-color: blue;
  width: 100%;
  height: 500px;
}

.Contacto {
  position: relative;
  display: block;
  float: left;
  margin: 80px 0px;
  padding: 0px 0px;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
}

.Contacto p.upper {
  position: relative;
  display: block;
  float: left;
  width: 80%;
  margin: 30px 10%;
  font-size: 5vmin;
  text-align: center;
  padding: 0px;
  color: rgba(0, 0, 0);
}

.Contacto p {
  position: relative;
  display: block;
  float: left;
  width: 80%;
  margin: 10px 10%;
  padding: 0px;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 2.8vmin;
  color: rgba(0, 0, 0);
}

.Contacto .tarjetai {
  position: relative;
  display: block;
  float:right;
  margin-top: 5%;
  margin-left: auto;
  margin-right: 10%;
  padding: 0;
  width: auto;
  height: 100%;
  background-color: transparent;
}

.Contacto .tarjetad {
  position: relative;
  display: block;
  float: left;
  margin-top: 5%;
  margin-right: auto;
  margin-left: 10%;
  padding: 0;
  width: auto;
  height: 100%;
  background-color: transparent;
}

.Contacto svg {
  display: block;
  float: left;
  width: 100%;
  height: 100px;
  margin: 30px 0;
  padding: 0;
  /* background-color: red; */
}

.Contacto span.titulos {
  display: block;
  position: relative;
  text-align: center;
  font-size: 1.5rem;
  padding: 0 0;
  margin: 0;

}

.middle {
  margin: 100px;
}