.Modal {
    position:fixed;
    overflow: hidden;
    float: left;
    top: 30px;
    padding: 0;
    margin: 30px 5%;
    height: auto;
    width: 90%;
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.9);
    z-index: 2;
  }

  .Modal-show{
    display: block;
    height: calc(100% - 80px);
  }

  .Modal-hide{
    display: none;
    height: 0px;
  }

  .modal-ctrl{
    position: relative;
    display: block;
    float: left;
    width: 100%;
    height: auto;
    background-color: rgb(0, 153, 102);
  }

  .modal-ctrl button.close{
    display: block;
    position: relative;
    float: right;
    margin: 15px 15px;
    padding: 0;
    height: 20px;
    width: 20px;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    border: none;
    background-color: red;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 3px rgba(255, 0, 0, 1) ;
  }

  .modal-ctrl button.close:hover{
    
    transform: scale(1.1);
    box-shadow: 0px 0px 2px 3px rgba(255, 0, 0, 1) ;
  }

  .modal-ctrl p{
    display: table-cell;
    vertical-align: middle;
    position: relative;
    float: left;
    height: 25px;
    vertical-align: middle;
    width: calc(100% - 70px);
    margin: 12.5px 10px;
    padding: 0;
    border: none;
    text-align:center;
    font-size: 20px;
  }