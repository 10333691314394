nav{
  display: block;
  position: fixed;
  margin: 0;
  padding: 0;
  height: 50px;
  width: 100%;
  background-color: transparent;
  color: rgb(255, 255, 255); 
  top: 0px;
  align-items: center;
z-index: 1;
}

/* nav div {
  position: relative;
  display: table;
  float: left;
  background-color: blue;
  font-family: 'Courier New', Courier, monospace;
  width: calc(100%/3);
  margin: 0;
  padding: 0;
  text-align: center;
  align-content: center;
  vertical-align: middle;
} */

nav > div.navlogo{
  position: relative;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 50%;
  height: 100%;
  background-color: transparent;
}

nav > div.menu{
  position: relative;
  display: block;
  float: right;
  margin: 0;
  padding: 0 0;
  width: auto;
  height: 100%;
  background-color: transparent;
}


.mouse-hand:hover{
  cursor:pointer;
}

nav > div > svg.logo{
  display: block;
  position: relative;
  float: left;
  margin:0 10px;
  padding: 0;
  background-color: transparent;
  height: 100%;
  width: 120px;
}

/* nav div .logo, nav .logo g.logo-svg-c1 path, nav .logo g.logo-svg-c2 path {
  position: relative;
  display: block;
  float: left;
  width: 10%;
  height: 30px;
  margin: 0;        
  padding: 0;
} */

nav div .ham{
  display: none;
}

nav div button{
  position: relative;
  display: block;
  float:left;
  width: auto;
  height: 100%;
  background-color: transparent;
  border:0;
  color: currentColor;
  text-align: center;
  font-size: 1rem;
  padding: 0;
  margin: 0 10px;
}

nav div svg.icon{
  position: relative;
  display: block;
  float:right;
  height: 100%;
  width: 30px;
  padding: 0;
  margin: 0 10px;
  background-color:transparent;
}
nav.an-nav {
  animation: an-nav 0.8s alternate forwards;
}

nav.an-navn {
  animation: an-navn 0.8s alternate forwards;

}

div.an-nav {
  background-color: transparent;
  animation:none;
}

div.an-navn {
  background-color: transparent;
  animation:none;
}

  @keyframes an-nav {
    0% {
      background-color: rgb(255, 255, 255,0);
         color: rgb(255, 255, 255); 
    }
  
    20%{
      color: rgb(0, 168, 89);
    }
  
    100% {
      background-color: rgb(255, 255, 255,1);
      color: rgb(0, 168, 89);
      
    }
  }
  
  @keyframes an-navn {
    0% {
          background-color:  rgb(255, 255, 255,1);
          color: rgb(0, 168, 89);
    }
  
    20%{
      color: rgb(255, 255, 255);
    }
  
    100% {
      background-color: rgb(255, 255, 255,0);
      color: rgb(255, 255, 255);
      
      
    }
  }