.an-sc-opa-0p5-inf-onhover:hover {
    animation: an-sc-opa-05 0.8s infinite alternate;
}

.an-opa-0p5-inf {
    animation: an-opa-05 0.8s infinite alternate;
}

.an-opa-0p5 {
    animation: an-opa-05 0.9s 2 alternate;
}

.an-y-top {
    animation: an-y-left 2s cubic-bezier(0.5, 0, 0.5, 1);
}

.an-opa-0p5-inf-onhover:hover {
    animation: an-opa-05 0.8s infinite alternate;
}

.tr-opa-0p5-3y {
    transition: opacity,translate, 0.5s ease-in-out;
}

.tr-opa-0p5-3y:hover {
    opacity: 0.5;
    transform: translateY(3px);
}

.tr-opa-0p5 {
    transition: opacity, 0.5s ease-in-out;
}

.tr-opa-0p5:hover {
    opacity: 0.5;
}

@keyframes an-sc-opa-05 {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0.9);
        opacity: 0.5;
    }
}

@keyframes an-y-left {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes an-opa-05 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
}

@keyframes an-opa-0to1 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}