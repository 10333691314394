@media all and (max-width:700px) {

  .fondo p {
    position: relative;
    display: table;
    padding: 0;
    margin-top: 20%;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    color: white;
    height: auto;
    text-align: center;
    font-size: 7vmin;
    
  }
  
  .fondo p.lower {
    position: relative;
    display: table;
    padding: 0;
    margin: 5% 20%;
    width: 60%;
    color: white;
    height: auto;
    text-align:justify;
    font-size: 6vmin;
    
  }
  
  .fondo button {
    display: block;
    padding: 15px 0;
    margin: 50px 30%;
    width: 40%;
    height: auto;
    text-decoration: none;
    background-color: rgb(0, 168, 89);
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    border-radius: 30px;
    border: 0;
    box-shadow: rgb(0, 0, 0, 0.3) 0px 0px 30px 0px;
    transition: box-shadow, background-color, 0.5s ease;
  }

  .Contacto .tarjetai, .Contacto .tarjetad {
    position: relative;
    display: block;
    float: left;
    margin: 5% auto;
    padding: 0 0;
    width: 100%;
    height: 100%;
  }

  .Contacto svg {
    display: block;
    float: left;
    width: 100px;
    height: auto;
    margin: 30px calc(50vw - 50px);
    padding: 0;
  }

  .Contacto span.titulos {
    display: block;
    position: relative;
    text-align: center;
    font-size:1.5rem;
    padding: 0 0;
    margin: 0;
  
  }
  
}