.card {

    position: relative;
    display: table;
    float: left;
    margin: 1% 2%;
    padding: 0;
    width: calc(88%/3);
    height: 100%;
    /* background-color:blueviolet; */
}

.card-image {
    position: relative;
    display: table-row;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    text-align: center;
    vertical-align: middle;
    /* background-color: brown; */
}

.card-header {
    position: relative;
    display: table-row;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    vertical-align: middle;
    /*     background-color: yellow; */
}

.card-body {
    position: relative;
    display: table-row;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-weight: 100;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.5rem;
    text-align: center;
    /* background-color: blue; */
}

@media all and (max-width:800px) {


    .card {
        position: relative;
        display: block;
        float: left;
        margin: 2% 10%;
        padding: 0;
        width: 80%;
        height: 100%;
        border: none;
    }

}

@media all and (min-width:800px) and (max-width:1000px)  {


    .card {
        position: relative;
        display: block;
        float: left;
        margin: 2% 5%;
        padding: 0;
        width: calc(80%/2);
        height: 100%;
         
    }

}