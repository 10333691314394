@media all and (max-width:1030px){
    nav{
        display: block;
        position: fixed;
        margin: 0;
        padding: 0;
        height: 50px;
        width: 100%;
        color: rgb(0, 0, 0);
        top: 0px;
        align-items: center;
        z-index: 1;
      }

      nav > div.navlogo{
        position: relative;
        display: block;
        float: left;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        font-family: 'Courier New', Courier, monospace;
        text-align: center;
        background-color: transparent;
      }

      nav > div > svg.logo{
        position: relative;
        display: block;
        float: left;
        margin:0 10px;
        padding: 0;
        height: 50px;
        width: 100px;
      }

      nav > div > svg.icon{
        position: relative;
        display: block;
        float:right;
        height: auto;
        width: 50px;
        padding: 0;
        margin: 20px calc(25% - 25px);
        background-color:transparent;
      }

      nav div.menu{
        position: fixed;
        display: block;
        overflow: scroll;
        top:0;
        height: auto;
        width: 100%;
        opacity: 0;
        padding-top: 60px;
        color: rgb(255, 255, 255);
        background-color: white;
        transform: translateY(-100%);
        
      }

      nav div .ham{
        position: relative;
        display: block;
        float: right;
        width: auto;
        padding: 7.5px;
        margin: 0;
        height: 35px;
        border-radius: 30px;
        background-color: white;
      }

      .deg90{
        animation: rotate90 0.5s linear forwards;
      }

      .deg0{
        animation: rotate0 0.5s linear;
      }

      nav div button{
        background-color: rgb(0, 168, 89);
        position: relative;
        display: table-cell;
        float: left;
        width: 70%;
        height: auto;
        margin: 10px calc(30% - 30%/2);
        padding: 10px 0;
        text-decoration: none;
        text-align: center;
        box-shadow: black 0 0 5px;
        border-radius: 30px;
        font-size: 1.5rem;
      }

      nav.an-nav {
        background-color: transparent;
        animation: none;
      }
      
      nav.an-navn {
        background-color: transparent;
        animation: none;
      }

      div.an-nav {
        animation: an-nav 0.8s alternate forwards;
        
      }
      
      div.an-navn {
        animation: an-navn 0.8s alternate forwards;
      }

      /* nav div.an-nav {
        animation: an-nav 0.8s alternate forwards;
      }
      
      nav div.an-navn {
        animation: an-navn 0.8s alternate forwards;
      } */
      

      nav div.show-nav{
       
        animation: show-nav 0.5s linear forwards; 
      }

      nav div.hide-nav{
      
        animation: hide-nav 0.5s linear forwards;
      }

      @keyframes show-nav {
        0%{
          
          opacity: 1;
          transform: translateY(-100%);
        }
        100%{
          opacity: 1;
          
          transform: translateY(0%);
        }
        
      }
      
      @keyframes hide-nav {
        0%{
          
          opacity: 1;
          transform: translateY(0%);
          
        }

        100%{
          opacity: 1;
         
          transform: translateY(-100%);  
        }
      }

      @keyframes rotate90 {
        0%{
          transform:rotate(0deg);
        }
        100%{
          transform: rotate(-90deg);
        }
      }
      
      @keyframes rotate0 {
        0%{
          transform: rotate(-90deg);
        }
        100%{
          transform: rotate(0deg);
        }
      }
      
}